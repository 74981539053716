import React from 'react';

import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

const NotFoundPage = () => (
  <Layout path="">
    <SEO title="404: Not found" />
    <Container style={{ textAlign: 'center' }}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
